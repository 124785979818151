<template>
  <div id="campClaim">
    <!-- 弹框 -->
    <el-dialog
      :title="campClaimFormTitle"
      width="1200px"
      :visible.sync="campClaimDialogVisible"
      :close-on-click-modal="false"
      @close="campClaimDialogClose"
    >
      <el-form
        ref="campClaimFormRef"
        :model="campClaimForm"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="营地名称" prop="name">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.name || '-' }}</span>
              <el-input v-else v-model="campClaimForm.name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="营地类型" prop="type">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.type || '-' }}</span>
              <el-input v-else v-model="campClaimForm.type" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="认领用户" prop="masterName">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.masterName || '-' }}</span>
              <el-input v-else v-model="campClaimForm.masterName" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="手机号码" prop="tel">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.tel || '-' }}</span>
              <el-input v-else v-model="campClaimForm.tel" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营业执照" prop="businessLicense">
              <el-image
                v-if="campClaimForm.businessLicense"
                style="width: 200px; height: 200px"
                fit="contain"
                :src="baseUrl + campClaimForm.businessLicense"
                :preview-src-list="[baseUrl + campClaimForm.businessLicense]"
              />
              <span v-else>{{ '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证正面" prop="cardFront">
              <el-image
                v-if="campClaimForm.cardFront"
                style="width: 200px; height: 200px"
                fit="contain"
                :src="baseUrl + campClaimForm.cardFront"
                :preview-src-list="[baseUrl + campClaimForm.cardFront]"
              />
              <span v-else>{{ '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证反面" prop="cardBack">
              <el-image
                v-if="campClaimForm.cardBack"
                style="width: 200px; height: 200px"
                fit="contain"
                :src="baseUrl + campClaimForm.cardBack"
                :preview-src-list="[baseUrl + campClaimForm.cardBack]"
              />
              <span v-else>{{ '-' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="认领审核" prop="campOwnerStatus">
              <template v-if="campClaimFormTitle === '认领详情'">
                <span v-if="campClaimForm.campOwnerStatus === 1">通过</span>
                <span v-else-if="campClaimForm.campOwnerStatus === -1">不通过</span>
                <span v-else>{{ '-' }}</span>
              </template>
              <el-select
                v-else
                v-model="campClaimForm.campOwnerStatus"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in [
                    { label: '通过', value: 1 },
                    { label: '不通过', value: -1 },
                  ]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="认领备注" prop="remarks">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.remarks || '-' }}</span>
              <el-input
                v-else
                v-model="campClaimForm.remarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="campClaimFormTitle === '认领详情'">
          <el-col :span="8">
            <el-form-item label="审核时间" prop="reviewTime">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.reviewTime || '-' }}</span>
              <el-input v-else v-model="campClaimForm.reviewTime" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作员" prop="reviewer">
              <span v-if="campClaimFormTitle === '认领详情'">{{ campClaimForm.reviewer || '-' }}</span>
              <el-input v-else v-model="campClaimForm.reviewer" readonly />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="campClaimDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="campClaimFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 搜索区 -->
    <el-form inline size="small">
      <el-form-item label="营地名称">
        <el-input
          v-model="searchForm.name"
          placeholder="请输入营地名称"
          clearable
          @keyup.enter.native="handleSearch"
        />
        <el-input type="text" style="display: none" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="campClaimPage.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column
        prop="id"
        label="营地ID"
        width="80"
        align="center"
      />
      <el-table-column label="创建日期" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.createTime">{{ scope.row.createTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="营地名称" align="center" />
      <el-table-column label="营地类型" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">公共营地</span>
          <span v-if="scope.row.type === 2">收费营地</span>
          <span v-if="scope.row.type === 3">野营地</span>
          <span v-if="scope.row.type === 4">酒店营地</span>
        </template>
      </el-table-column>
      <el-table-column label="营业执照" width="100" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.businessLicense"
            style="width: 50px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.businessLicense"
            :preview-src-list="[baseUrl + scope.row.businessLicense]"
          />
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证正面" width="100" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.cardFront"
            style="width: 50px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.cardFront"
            :preview-src-list="[baseUrl + scope.row.cardFront]"
          />
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证反面" width="100" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.cardBack"
            style="width: 50px; height: 100px"
            fit="contain"
            :src="baseUrl + scope.row.cardBack"
            :preview-src-list="[baseUrl + scope.row.cardBack]"
          />
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="认领状态" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.campOwnerStatus === 1">已认领</span>
          <span v-else>未认领</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="masterName"
        label="认领用户"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.masterName">{{ scope.row.masterName }}</span>
          <span v-else>{{ '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      :total="campClaimPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { selectCampClaimInfo, selectCampClaimList, review } from '@/api/main/campClaim'

export default {
  data () {
    return {
      campClaimDialogVisible: false,
      campClaimFormTitle: '',
      campClaimForm: {
        id: '',
        name: '',
        type: '',
        masterName: '',
        tel: '',
        businessLicense: '',
        cardFront: '',
        cardBack: '',
        campOwnerStatus: '',
        remarks: '',
        reviewTime: '',
        reviewer: ''
      },
      campClaimPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      baseUrl: 'https://oss-quye.huahuihongxin.com/'
    }
  },
  created () {
    selectCampClaimList(this.searchForm).then((res) => {
      this.campClaimPage = res
    })
  },
  methods: {
    // 点击搜索触发的回调
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCampClaimList(this.searchForm).then((res) => {
        this.campClaimPage = res
      })
    },
    // 修改当前页触发的回调
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCampClaimList(this.searchForm).then((res) => {
        this.campClaimPage = res
      })
    },
    // 每页展示的数据发生修改时触发的回调
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCampClaimList(this.searchForm).then((res) => {
        this.campClaimPage = res
      })
    },
    campClaimDialogClose () {
      this.$refs.campClaimFormRef.resetFields()
    },
    campClaimFormSubmit () {
      if (this.campClaimFormTitle === '认领详情') {
        this.campClaimDialogVisible = false
        return
      }
      this.$refs.campClaimFormRef.validate(async (valid) => {
        if (valid) {
          await review({
            id: this.campClaimForm.id,
            campOwnerStatus: this.campClaimForm.campOwnerStatus,
            remarks: this.campClaimForm.remarks
          })
          this.campClaimPage = await selectCampClaimList(this.searchForm)
          this.campClaimDialogVisible = false
        }
      })
    },
    // 点击审核触发的回调
    handleReview (index, row) {
      this.campClaimFormTitle = '认领审核'
      this.campClaimDialogVisible = true
      this.selectCampClaimInfoById(row.id)
    },
    // 点击详情触发的回调
    handleInfo (index, row) {
      this.campClaimFormTitle = '认领详情'
      this.campClaimDialogVisible = true
      this.selectCampClaimInfoById(row.id)
    },
    selectCampClaimInfoById (id) {
      selectCampClaimInfo(id).then((res) => {
        this.campClaimForm.id = res.id
        this.campClaimForm.name = res.name
        if (res.type === 1) {
          this.campClaimForm.type = '公共营地'
        } else if (res.type === 2) {
          this.campClaimForm.type = '收费营地'
        } else if (res.type === 3) {
          this.campClaimForm.type = '野营地'
        } else {
          this.campClaimForm.type = '酒店营地'
        }
        this.campClaimForm.masterName = res.masterName
        this.campClaimForm.tel = res.tel
        this.campClaimForm.businessLicense = res.businessLicense
        this.campClaimForm.cardFront = res.cardFront
        this.campClaimForm.cardBack = res.cardBack
        this.campClaimForm.campOwnerStatus = res.campOwnerStatus
        this.campClaimForm.remarks = res.remarks
        this.campClaimForm.reviewTime = res.reviewTime
        this.campClaimForm.reviewer = res.reviewer
      })
    }
  }
}
</script>

<style></style>
