import axios from '@/common/axios'
import qs from 'qs'

export function selectCampClaimInfo (id) {
  return axios({
    method: 'get',
    url: '/camp/campInfo/' + id
  })
}

export function selectCampClaimList (query) {
  return axios({
    method: 'get',
    url: '/camp/claimList',
    params: query
  })
}

export function review (data) {
  return axios({
    method: 'put',
    url: '/campWeb/campOwnerAudit',
    data: qs.stringify(data)
  })
}
